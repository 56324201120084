import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { shallowEqual, useSelector } from "react-redux";
import * as TYPE from './lib/contrant'


const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

const Dashboard = lazy(() =>
  import("./pages/Dashboard")
);

const ListUserAdmin = lazy(() =>
  import("./pages/Admin/ListUserAdmin")
);
const ListTask = lazy(() =>
  import("./pages/Task/ListTask")
);
const ListUserClient = lazy(() =>
  import("./pages/User/ListUser")
);
const ListUser = lazy(() =>
  import("./pages/User/ListUser")
);

const UserProfile = lazy(() =>
  import("./pages/User/UserProfile")
);

const ListTeam = lazy(() =>
  import("./pages/LeaderBoard/ListTeam")
)

const HNGDrops = lazy(() =>
  import("./pages/HNGDrops/index")
)

const HNGDropsDetail = lazy(() =>
  import("./pages/HNGDrops/detailDay")
)

const HNGDropsDetailRef = lazy(() =>
  import("./pages/HNGDrops/detailRef")
)

const DailyInvite = lazy(() =>
  import("./pages/DailyInvite/index.jsx")
)

const DetailTeam = lazy(() =>
  import("./pages/LeaderBoard/DetailTeam")
)

const DailyInviteDetail = lazy(() =>
  import("./pages/DailyInvite/detailDay")
)

const ChangePassword = lazy(() =>
  import("./pages/User/ChangePassword")
);

const AdminProfile = lazy(() =>
  import("./pages/User/AdminProfile")
);

const AssetsManagement = lazy(() =>
  import("./pages/Assets/AssetsManagement")
);

const HistoryManagement = lazy(() =>
  import("./pages/Assets/HistoryManagement")
);

const DailyCodeManagement = lazy(() =>
  import("./pages/DailyCode/DailyCodeManagement")
);

const CompletedDailyCodeList = lazy(() =>
  import("./pages/DailyCode/DailyCodeCompletedList")
);

const CompletedDailyCodeListDetail = lazy(() =>
  import("./pages/DailyCode/DailyCodeCompletedListDetail")
);

const ConfigureProgram = lazy(() =>
  import("./pages/LuckyMoney/ConfigureProgram")
)

const LuckyHistory = lazy(() => import("./pages/LuckyMoney/LuckyHistory"));

const TonAddress = lazy(() => import("./pages/Config/TonAddress"));

const ListUserCheckin = lazy(() =>
  import("./pages/User/ListUserCheckin")
);

const DetailUserCheckin = lazy(() =>
  import("./pages/User/DetailUserCheckin")
);

export default function BasePage() {
  const { listUserLoginPermission } = useSelector(state => ({
    listUserLoginPermission: state.authReducer.listUserLoginPermission,
  }), shallowEqual)

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        {/* Trang chủ */}
        <Route path="/dashboard" component={Dashboard} exact />
        {/* end */}

        <Route path="/user-profile" component={UserProfilepage} exact />
        {<Route path="/assets/list" component={AssetsManagement} exact />}
        {<Route path="/history/:id" component={HistoryManagement} exact />}
        {/* Quản lý người dùng */}
        {<Route path="/admin/list-user" component={ListUserAdmin} exact />}
        {<Route path="/task/list" component={ListTask} exact />}
        {listUserLoginPermission.includes(TYPE.QUAN_LY_NGUOI_DUNG) && <Route path="/user/list-user" component={ListUser} exact />}
        <Route path="/user/profile/:id" component={UserProfile} exact />

        {/* Quản lý bảng xếp hạng */}
        {<Route path="/team/list" component={ListTeam} exact />}
        {<Route path="/team/daily" component={HNGDrops} exact />}
        {<Route path="/team/invite" component={DailyInvite} exact />}
        {<Route path="/team/daily/detail/:day" component={HNGDropsDetail} exact />}
        {<Route path="/team/invite/detail/:day" component={DailyInviteDetail} exact />}
        {<Route path="/team/daily/detail/ref/:id" component={HNGDropsDetailRef} exact />}
        {<Route path="/team/:id" component={DetailTeam} exact />}
        <Route
          path="/lucky-draw/list"
          component={ConfigureProgram}
        />
        <Route
          path="/lucky-draw/history/:configureProgramId"
          component={LuckyHistory}
        />
        {/* Quản lý người chơi */}
        {<Route path="/user/list" component={ListUserClient} exact />}
        {<Route path="/user/checkin" component={ListUserCheckin} exact />}
        {<Route path="/user/checkin/:id" component={DetailUserCheckin} exact />}
        {/* end */}

        {/* Quản lý admin */}
        {listUserLoginPermission.includes(TYPE.DOI_MAT_KHAU) && <Route path="/admin/change-password" component={ChangePassword} exact />}
        {listUserLoginPermission.includes(TYPE.TRANG_CA_NHAN_ADMIN) && <Route path="/admin/profile" component={AdminProfile} exact />}
        {/* end */}

        {/* Quản lý mật mã ngày */}
        <Route path="/daily-code/list" component={DailyCodeManagement} exact />
        <Route path="/daily-code/completed" component={CompletedDailyCodeList} exact />
        <Route path="/daily-code/completed/detail/:date" component={CompletedDailyCodeListDetail} exact />
        {/* end */}

        {/* Quản lý cấu hình */}
        <Route path="/config/address-ton" component={TonAddress} exact />
        {/* end */}
        <Redirect from="/login" to="/" />
      </Switch>
    </Suspense>
  );
}
