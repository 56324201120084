export const capitalizeFirstLetter = (string) => {
    if (!string || string === "") {
        return ""
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const validateEmailWithoutSpecial = (email) => {
    if (!email || email === "") {
        return ""
    }
    let re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return re.test(email);
}

export const validatePassword = (password) => {
    if (!password || password === "") {
        return ""
    }
    let re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/g;
    return re.test(password);
}

export const convertUTCTimeToLocalTime = (UTCDateString) => {
    var convertdLocalTime = new Date(UTCDateString);

    var hourOffset = convertdLocalTime.getTimezoneOffset() / 60;

    convertdLocalTime.setHours(convertdLocalTime.getHours() + hourOffset);

    return convertdLocalTime;
}

export const formatMoney = (num) => {
    if (!num || num === "") return "0"
    return Number(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const formatMoneyCoin = (num, fixed = 3) => {
    if (!num || num === '') return '0';
    let data = Number(Number(num || '0').toFixed(fixed)).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    data = data.replace(/,/g, '-');
    data = data.replace(/\./g, ',');
    data = data.replace(/-/g, '.');
    return data;
}

export const formatNumberCoin = (num) => {
    if (!num) return 0
    num = parseFloat(num).toFixed(8)
    return parseFloat(num).toLocaleString("en", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 8
    });
}

export const SECRET_KEY = "pOWI7geiVlbr1aBRbBpbGYA02fsb7O";

export const upperCaseFirstString = (string) => {
    if (!string || string === "") return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const blackListInputNumber = (e) => {
    // Allow: backspace, delete, tab, escape, enter and .
    if ([46, 8, 9, 27, 13, 110].includes(e.keyCode)
        // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
        || ((e.keyCode === 65 || e.keyCode === 86 || e.keyCode === 67) && (e.ctrlKey === true || e.metaKey === true))
        // Allow: home, end, left, right, down, up
        || (e.keyCode >= 35 && e.keyCode <= 40)
    ) {
        return true
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
        return false
    }
    return true
}

export const blackListInputNumberWithoutDot = (e) => {
    // Allow: backspace, delete, tab, escape, enter and .
    if ([46, 8, 9, 27, 13, 110, 190].includes(e.keyCode)
        // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
        || ((e.keyCode === 65 || e.keyCode === 86 || e.keyCode === 67) && (e.ctrlKey === true || e.metaKey === true))
        // Allow: home, end, left, right, down, up
        || (e.keyCode >= 35 && e.keyCode <= 40)
    ) {
        return true
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
        return false
    }
    return true
}

export const apiUploadUrl = `${process.env.REACT_APP_API_URL}/uploads/`

export const convertToSlug = (str) => {
    // Chuyển hết sang chữ thường
    str = str.toLowerCase();

    // xóa dấu
    str = str
        .normalize('NFD') // chuyển chuỗi sang unicode tổ hợp
        .replace(/[\u0300-\u036f]/g, ''); // xóa các ký tự dấu sau khi tách tổ hợp

    // Thay ký tự đĐ
    str = str.replace(/[đĐ]/g, 'd');

    // Xóa ký tự đặc biệt
    str = str.replace(/([^0-9a-z-\s])/g, '');

    // Xóa khoảng trắng thay bằng ký tự -
    str = str.replace(/(\s+)/g, '-');

    // Xóa ký tự - liên tiếp
    str = str.replace(/-+/g, '-');

    // xóa phần dư - ở đầu & cuối
    str = str.replace(/^-+|-+$/g, '');

    // return
    return str;
}

export const checkString = (str) => {
    var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (str.match(format)) {
        return true;
    } else {
        return false;
    }
}

export const jsonToHtml = (jsonStr) => {
    // const obj = JSON.parse(jsonStr);

    let html = '';
    jsonStr["blocks"].forEach(function (block, index) {
        switch (block['type']) {
            case 'paragraph':
                html += '<p>' + block['data']['text'] + '</p>';
                break;

            case 'header':
                html += '<h' + block['data']['level'] + '>' + block['data']['text'] + '</h' + block['data']['level'] + '>';
                break;

            case 'raw':
                html += block['data']['html'];
                break;

            case 'list':
                const lsType = (block['data']['style'] === 'ordered') ? 'ol' : 'ul';
                html += '<' + lsType + '>';
                block['data']['items'].forEach(function (item, index) {
                    html += '<li>' + item + '</li>';
                });
                html += '</' + lsType + '>';
                break;

            case 'code':
                html += '<pre><code class="language-' + block['data']['lang'] + '">' + block['data']['code'] + '</code></pre>';
                break;

            case 'image':
                html += '<div class="img_pnl"><img src="' + block['data']['file']['url'] + '" /></div>';
                break;
            case 'simpleImage':
                html += '<div class="img_pnl"><img src="' + block['data']['url'] + '" /></div>';
                break;
            case 'embed':
                if (block['data']['service'] === 'youtube') {
                    html += '<div class="embed-responsive embed-responsive-16by9"><iframe class="embed-responsive-item" style="width:100%;height:500px" src="' + block['data']['embed'] + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>';
                }
                break;

            default:

                break;
        }
    });
    return html;
}

export const jsonToText = (jsonStr) => {
    // const obj = JSON.parse(jsonStr);

    let html = '';
    jsonStr["blocks"].forEach(function (block, index) {
        switch (block['type']) {
            case 'paragraph':
                html += '' + block['data']['text'] + `
`;
                break;
            default:
                break;
        }
    });

    return html;
}

export const checkNoImg = (name, image) => {
    if (image) {
        return image
    }
    if (name) {
        return `https://icotar.com/initials/${encodeURI(name)}.png?bg=06527E`
    }
    return 'https://icotar.com/avatar/craig.png?bg=06527E'
}

export const formatMoneyNormal = (text) => {
    return text.toLocaleString()
}
export const formatCoinID = (amount, coin_id) => {

    switch (coin_id) {
        case 'gold_prize':
            return formatGold(amount) + " GOLD"
        case 'hng_prize':
            return numberFormat(amount, 5, ',', '.') + " HNG";
        case 'usdt_prize':
            return numberFormat(amount, 5, ',', '.') + " USDT";
        case 'xaut_prize':
            return numberFormat(amount, 5, ',', '.') + " OUNCE";
        default:
            return formatMoneyCoin(amount) + " VNĐ"
    }
}

export const formatCoin = (amount, coin_id) => {

    switch (coin_id) {
        case 1:
            return formatMoney(amount);
        case 2:
            return formatGold(amount);
        case 3:
            return numberFormat(amount, 5, ',', '.');
        case 4:
            return numberFormat(amount, 5, ',', '.');
        case 5:
            return numberFormat(amount, 5, ',', '.');
        default:
            return numberFormat(amount, 5, ',', '.')
    }
}

export const formatGold = (num) => {
    if (!num || num === "") return "0";
    let str = num.toString();
    const idx_nega = str.indexOf("-");
    let nega = "";
    if (idx_nega !== -1) {
        str = str.replace("-", "");
        nega = "-";
    }
    const idx = str.indexOf(".");
    if (idx === -1) {
        let string = str.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        return nega + string;
    } else {
        const pre = str.slice(0, idx);
        let aft = str.slice(idx + 1, idx + 6);

        let fix_pre = pre.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        let data = fix_pre;
        data = data.replace(/,/g, "*");
        data = data.replace(/\./g, ",");
        data = data.replace(/\*/g, ".");
        let sum = 0;
        for (let i = 0; i < aft.length; i++) {
            sum = sum + Number(aft[i]);
        }
        if (sum === 0) {
            aft = "";
        } else {
            aft = `,${aft}`;
        }
        data = nega + data + aft;
        if (data.indexOf(",00000") > -1) {
            data = data.replace(",00000", "");
        }
        return data;
    }
};

export const numberFormat = (number, decimals, decPoint, thousandsSep) => { // eslint-disable-line camelcase
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
    const n = !isFinite(+number) ? 0 : +number
    const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
    const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
    const dec = (typeof decPoint === 'undefined') ? '.' : decPoint
    let s = ''
    const toFixedFix = function (n, prec) {
        if (('' + n).indexOf('e') === -1) {
            return +(Math.round(n + 'e+' + prec) + 'e-' + prec)
        } else {
            const arr = ('' + n).split('e')
            let sig = ''
            if (+arr[1] + prec > 0) {
                sig = '+'
            }
            return (+(Math.round(+arr[0] + 'e' + sig + (+arr[1] + prec)) + 'e-' + prec)).toFixed(prec)
        }
    }
    // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec).toString() : '' + Math.round(n)).split('.')
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || ''
        // s[1] += new Array(prec - s[1].length + 1).join('0')
    }
    if (s[1] === '') {
        return s[0]
    }
    return s.join(dec)
}
