import { Types } from "./types";

const initialState = {
  dataUserCheckin: {
    paging: {},
    rows: [],
  },
  detailUserCheckin: {
    paging: {},
    rows: [],
  },
};
const checkinReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SAVE_LIST_USER_CHECKIN:
      return {
        ...state,
        dataUserCheckin: action.payload,
      };
    case Types.SAVE_DETAIL_USER_CHECKIN:
      return {
        ...state,
        detailUserCheckin: action.payload,
      };
    default:
      return { ...state };
  }
};
export default checkinReducer;
