import { Types } from './types'

const initialState = {
    dataCampaign: {
        rows: [],
        total: 0,
    },
    dataHistoryReward: {
        rows: [],
        total: 0,
    },
    dataConfigureProgram: {
        rows: []
    },
    dataEvoucherTemplates: {
        rows: [],
        total: 0,
    },
    dataConfigEvoucherProgram: {}
}
const homeReducer = (state = initialState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case Types.SAVE_CAMPAIGN:
            return {
                ...newState,
                dataCampaign: action.payload
            }
        case Types.SAVE_HISTORY_REWARD:
            return {
                ...newState,
                dataHistoryReward: action.payload
            }
        case Types.SAVE_CONFIGURE_PROGRAM:
            return {
                ...newState,
                dataConfigureProgram: action.payload
            }
        // case Types.SAVE_LIST_CONFIG:
        //     return {
        //         ...newState,
        //         dataConfig: action.payload
        //     }
        // case Types.SAVE_LOADINGS:
        //     return {
        //         ...newState,
        //         spin: action.payload
        //     }
        case Types.SAVE_LIST_TEMPLATE_EVOUCHER:
            return {
                ...newState,
                dataEvoucherTemplates: action.payload
            }
        case Types.SAVE_CONFIG_EVOUCHER_PROGRAM:
            return {
                ...newState,
                dataConfigEvoucherProgram: action.payload
            }
        default:
            return { ...newState }
    }
}
export default homeReducer
